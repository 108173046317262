module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alpine White | Fitas Clareadoras Dentais | Carvão Clareador | Clareamento","short_name":"Alpine White | Fitas Clareadoras Dentais | Carvão Clareador | Clareamento","description":"Descubra a gama de produtos Alpine White. Limpeza, cuidado e clareamento caseiro para um hálito fresco, gengivas saudáveis e dentes brancos.","background_color":"#ffffff","theme_color":"#316BAB","display":"fullscreen","icon":"src/images/favicon.png","start_url":"/","lang":"pt","localize":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6920960760db51bf20b05a78e25626d4"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
